import daytimeAdviceApi from "../../api/daytime_advices";
import * as mutationTypes from "../mutation-types";

const state = {
  assignedAdviceSheets: [],
  daytimeAdviceSheet: {},
  daytimeAdviceSheetsResult: [],
  activeDoctorsWithSheets: [],
  daytimeAdviceReferenceInfo: {},
  daytimeAdviceSheetStatuses: [],
  poolingCount: 0,
  userDaytimeAdviceSheets: [],
  daytimeAdviceImages: [],
  daytimeAdviceTimeoutId: null,
  assignedAdviceTimeoutId: null,
  // 検索条件を保持するためのオブジェクト
  daytimeAdviceSearchCondition: {
    date: null,
    status: null,
    doctorFamilyName: null,
    doctorFirstName: null,
  },
  autoCalcChildAge: null,
  daytimeAdviceMediaFile: null,
};

const getters = {
  assignedAdviceSheets: state => state.assignedAdviceSheets,
  daytimeAdviceSheet: state => state.daytimeAdviceSheet,
  daytimeAdviceSheetsResult: state => state.daytimeAdviceSheetsResult,
  activeDoctorsWithSheets: state => state.activeDoctorsWithSheets,
  daytimeAdviceReferenceInfo: state => state.daytimeAdviceReferenceInfo,
  daytimeAdviceSheetStatuses: state => state.daytimeAdviceSheetStatuses,
  poolingCount: state => state.poolingCount,
  userDaytimeAdviceSheets: state => state.userDaytimeAdviceSheets,
  daytimeAdviceImages: state => state.daytimeAdviceImages,
  daytimeAdviceTimeoutId: state => state.daytimeAdviceTimeoutId,
  assignedAdviceTimeoutId: state => state.assignedAdviceTimeoutId,
  daytimeAdviceSearchCondition: state => state.daytimeAdviceSearchCondition,
  autoCalcChildAge: state => state.autoCalcChildAge,
  daytimeAdviceMediaFile: state => state.daytimeAdviceMediaFile,
};

const actions = {
  fetchAssignedAdviceSheets({ commit }) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchAssignedSheets(assignedAdviceSheets => {
        commit(mutationTypes.ASSIGN_ASSIGNED_ADVICE_SHEETS, { assignedAdviceSheets });
        resolve();
      });
    });
  },
  fetchDaytimeAdviceSheet({ commit }, params) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchSheet(daytimeAdviceSheet => {
        commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_SHEET, { daytimeAdviceSheet });
        resolve();
      }, params);
    });
  },
  searchDaytimeAdviceSheets({ commit }) {
    return new Promise((resolve) => {
      daytimeAdviceApi.searchSheets(daytimeAdviceSheets => {
        commit(mutationTypes.DAYTIME_ADVICE_SHEETS_RESULT, { daytimeAdviceSheets });
        resolve();
      }, state.daytimeAdviceSearchCondition);
    });
  },
  updateDaytimeAdviceSheet({ commit }, params) {
    return new Promise((resolve) => {
      daytimeAdviceApi.updateSheet(
        params,
        () => {
          // 更新後のデータを再取得
          daytimeAdviceApi.fetchSheet(daytimeAdviceSheet => {
            commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_SHEET, { daytimeAdviceSheet });
            resolve();
          }, params);
          resolve();
        },
        () => {
          resolve();
        }
      );
    });
  },
  finishDaytimeAdviceSheet({ commit }, params) {
    return new Promise((resolve) => {
      daytimeAdviceApi.finishSheet(() => {
        resolve();
      }, params);
    });
  },
  daytimeAdviceStart({ commit }) {
    return new Promise((resolve) => {
      daytimeAdviceApi.startHandling(() => {
        resolve();
      });
    });
  },
  daytimeAdviceFinish({ commit }) {
    return new Promise((resolve, reject) => {
      daytimeAdviceApi.finishHandling(() => {
        resolve();
      },
      () => {
        reject();
      });
    });
  },
  fetchActiveDoctorsWithSheets({ commit }) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchActiveDoctorsWithSheets(activeDoctorsWithSheets => {
        commit(mutationTypes.ASSIGN_ACTIVE_DOCTORS_WITH_SHEETS, { activeDoctorsWithSheets });
        resolve();
      });
    });
  },
  fetchDaytimeAdviceReferenceInfo({ commit }, params) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchReferenceInfo(referenceInfo => {
        commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_REFERENCE_INFO, { referenceInfo });
        resolve();
      }, params);
    });
  },
  fetchDaytimeAdviceSheetStatuses({ commit }) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchDaytimeAdviceSheetStatuses(statuses => {
        commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_SHEET_STATUSES, { statuses });
        resolve();
      });
    });
  },
  fetchPoolingCount({ commit }) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchPoolingCount(poolingCount => {
        commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_TODAYS_POOLING_COUNT, { poolingCount });
        resolve();
      });
    });
  },
  fetchUserDaytimeAdviceSheets({ commit }, params) {
    return new Promise((resolve, reject) => {
      daytimeAdviceApi.fetchUserDaytimeAdviceSheets(userDaytimeAdviceSheets => {
        commit(mutationTypes.ASSIGN_USER_DAYTIME_ADVICE_SHEETS, { userDaytimeAdviceSheets });
        resolve();
      },
      () => {
        reject();
      }, params);
    });
  },
  fetchDaytimeAdviceImages({ commit }, params) {
    return new Promise((resolve) => {
      daytimeAdviceApi.fetchImages(daytimeAdviceImages => {
        commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_IMAGES, { daytimeAdviceImages });
        resolve();
      }, params);
    });
  },
  assignDaytimeAdviceMonitorTimeoutId({ commit }, timeoutId ){
    commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_TIMEOUT_ID, { timeoutId });
  },
  assignAssignedAdviceTimeoutId({ commit }, timeoutId ){
    commit(mutationTypes.ASSIGN_ASSIGNED_ADVICE_TIMEOUT_ID, { timeoutId });
  },
  assignDaytimeAdviceSearchCondition({ commit }, condition ){
    return new Promise((resolve) => {
      commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_SEARCH_CONDITION, { condition });
      resolve();
    });
  },
  resetDaytimeAdviceSearch({ commit }){
    let condition = {
      date: null,
      status: null,
      doctorFamilyName: null,
      doctorFirstName: null
    };
    commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_SEARCH_CONDITION, { condition });
    let daytimeAdviceSheets = [];
    commit(mutationTypes.DAYTIME_ADVICE_SHEETS_RESULT, { daytimeAdviceSheets });
  },
  calcChildAge({ commit }, params) {
    return new Promise((resolve) => {
      daytimeAdviceApi.calcChildAge(childAge => {
        commit(mutationTypes.ASSIGN_AUTO_CALC_CHILD_AGE, { childAge });
        resolve();
      }, params);
    });
  },
  clearDaytimeAdviceSheet({ commit }) {
    let daytimeAdviceSheet = { load_finish: false };
    commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_SHEET, { daytimeAdviceSheet });
  },
  clearUserDaytimeAdviceSheets({ commit }) {
    let userDaytimeAdviceSheets = [];
    commit(mutationTypes.ASSIGN_USER_DAYTIME_ADVICE_SHEETS, { userDaytimeAdviceSheets });
  },

  viewDaytimeAdviceMedia({ commit }, mediaId) {
    return new Promise((resolve) => {
      daytimeAdviceApi.showMedia( mediaFile => {
        commit(mutationTypes.ASSIGN_DAYTIME_ADVICE_MEDIA_FILE, { mediaFile });
        resolve();
      }, mediaId);
    });
  },
};

const mutations = {
  [mutationTypes.ASSIGN_ASSIGNED_ADVICE_SHEETS](state, { assignedAdviceSheets }) {
    state.assignedAdviceSheets = assignedAdviceSheets;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_SHEET](state, { daytimeAdviceSheet }) {
    state.daytimeAdviceSheet = daytimeAdviceSheet;
  },
  [mutationTypes.DAYTIME_ADVICE_SHEETS_RESULT](state, { daytimeAdviceSheets }) {
    state.daytimeAdviceSheetsResult = daytimeAdviceSheets;
  },
  [mutationTypes.ASSIGN_ACTIVE_DOCTORS_WITH_SHEETS](state, { activeDoctorsWithSheets }) {
    state.activeDoctorsWithSheets = activeDoctorsWithSheets;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_REFERENCE_INFO](state, { referenceInfo }) {
    state.daytimeAdviceReferenceInfo = referenceInfo;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_SHEET_STATUSES](state, { statuses }) {
    state.daytimeAdviceSheetStatuses = statuses;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_TODAYS_POOLING_COUNT](state, { poolingCount }) {
    state.poolingCount = poolingCount;
  },
  [mutationTypes.ASSIGN_USER_DAYTIME_ADVICE_SHEETS](state, { userDaytimeAdviceSheets }) {
    state.userDaytimeAdviceSheets = userDaytimeAdviceSheets;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_IMAGES](state, { daytimeAdviceImages }) {
    state.daytimeAdviceImages = daytimeAdviceImages;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_TIMEOUT_ID](state, { timeoutId }) {
    state.daytimeAdviceTimeoutId = timeoutId;
  },
  [mutationTypes.ASSIGN_ASSIGNED_ADVICE_TIMEOUT_ID](state, { timeoutId }) {
    state.assignedAdviceTimeoutId = timeoutId;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_SEARCH_CONDITION](state, { condition }) {
    state.daytimeAdviceSearchCondition = condition;
  },
  [mutationTypes.ASSIGN_AUTO_CALC_CHILD_AGE](state, { childAge }) {
    state.autoCalcChildAge = childAge;
  },
  [mutationTypes.ASSIGN_DAYTIME_ADVICE_MEDIA_FILE](state, { mediaFile }) {
    state.daytimeAdviceMediaFile = mediaFile;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
