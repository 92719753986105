import epdsAnswerApi from "../../api/epds_answers";
import * as mutationTypes from "../mutation-types";

const state = {
  epdsAnswerList: [],
  epdsAnswer: {},
  epdsUrl: null,
};

const getters = {
  epdsAnswerList: state => state.epdsAnswerList,
  epdsAnswer: state => state.epdsAnswer,
  epdsUrl: state => state.epdsUrl,
};

const actions = {
  fetchEpdsAnswers({ commit }, params) {
    return new Promise((resolve, reject) => {
      epdsAnswerApi.fetchEpdsAnswers(epdsAnswerList => {
        commit(mutationTypes.ASSIGN_EPDS_ANSWER_LIST, { epdsAnswerList });
        resolve();
      },
      () => {
        reject();
      }, params);
    });
  },
  findEpdsAnswer({ commit }, params) {
    return new Promise((resolve) => {
      epdsAnswerApi.findEpdsAnswer(epdsAnswer => {
        commit(mutationTypes.ASSIGN_EPDS_ANSWER, { epdsAnswer });
        resolve();
      }, params);
    });
  },
  clearEpdsAnswers( { commit }) {
    let epdsAnswerList = {};
    commit(mutationTypes.ASSIGN_EPDS_ANSWER_LIST, { epdsAnswerList });
  },
  fetchEpdsUrl( { commit }, params) {
    return new Promise((resolve) => {
      epdsAnswerApi.fetchEpdsUrl(epdsUrl => {
        commit(mutationTypes.ASSIGN_EPDS_URL, { epdsUrl });
        resolve();
      }, params);
    });
  }
};
const mutations = {
  [mutationTypes.ASSIGN_EPDS_ANSWER_LIST](state, { epdsAnswerList }) {
    state.epdsAnswerList = epdsAnswerList;
  },

  [mutationTypes.ASSIGN_EPDS_ANSWER](state, { epdsAnswer }) {
    state.epdsAnswer = epdsAnswer;
  },

  [mutationTypes.ASSIGN_EPDS_URL](state, { epdsUrl }) {
    state.epdsUrl = epdsUrl;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
