import axios from "./../index";

export default {
  fetchUserAnytimeConsultations(callback, errorsCallback, params) {
    axios.get(`/api/v1/users/${params.userId}/anytime_consultations`, {
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
      errorsCallback();
    });
  }
};
