import axios from "./index";


export default {
  search(callback, errorsCallback, params) {
    axios.get("/api/v1/recommend_journals/search", {
      params: {
        reservation_id: params.reservationId,
        anytime_consultation_id: params.anytimeConsultationId
      },
      validateStatus: function(status) { return status < 500; },
    }).then(response => {
      if (response.status == 200) {
        callback(response.data);
      } else {
        callback([]);
      }
    }).catch(error => {
      console.log(error);
      errorsCallback();
    });
  }
};
