import recommendJournalApi from "../../api/recommend_journals";
import * as mutationTypes from "../mutation-types";


const state = {
  recommendJournals: {}
};


const getters = {
  recommendJournals: state => state.recommendJournals
};


const actions = {
  searchRecommendJournals({ commit }, params) {
    return new Promise((resolve, reject) => {
      recommendJournalApi.search(recommendJournals => {
        commit(mutationTypes.ASSIGN_RECOMMEND_JOURNALS, { recommendJournals });
        resolve();
      },
      () => {
        reject();
      }, params);
    });
  }
};


const mutations = {
  [mutationTypes.ASSIGN_RECOMMEND_JOURNALS](state, { recommendJournals }) {
    state.recommendJournals = recommendJournals;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
