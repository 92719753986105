import axios from "./index";

export default {
  fetchChatLogs(callback, errorsCallback, params) {
    axios.get("/api/v1/chat_logs/", {
      params: {
        chat_id: params.chatId,
        log_type: params.logType
      },
      validateStatus: function(status) { return status < 500; },
    }).then(response => {
      if (response.status == 200) {
        callback(response.data);
      } else {
        callback([]);
      }
    }).catch(error => {
      console.log(error);
      errorsCallback();
    });
  },
  
  markAsRead(callback, params) {
    axios.post("/api/v1/chat_logs/mark_as_read", {
      chat_id: params.chatId,
      log_type: params.logType
    }).then(() => {
      callback();
    }).catch(error => {
      console.log(error);
    });
  },
  
  post(callback, errorsCallback, params) {
    axios.post("/api/v1/chat_logs/", {
      chat_log: {
        content: params.content,
        content_type: params.contentType,
        attach_file_data_uri: params.fileUrl,
        attach_file_name: params.fileName,
      },
      chat_id: params.chatId,
      log_type: params.logType
    }).then(() => {
      callback();
    }).catch(error => {
      console.log(error);
      errorsCallback();
    });
  }

};
